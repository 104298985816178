import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as activityActions from '../../redux/budgeting/activity.slice'
import {
    emptyActivity,
    IActivity,
    IFetchActivityParams,
} from '../../models/budgeting/activity.model'
import { UpdateMode } from '../../models/update-mode.enum'
import ActivityService from '../../services/budgeting/activity.service'
import {
    emptyActivitySummary,
    IActivitySummary,
} from '../../models/budgeting/activity-summary.model'
import { useConfiguration } from '../configuration.hook'
import { useTransactionCode } from './transaction-code.hook'
import { useUser } from '../shared/user/user.hook'
import { useCurrentUser } from '../common/current-user/current-user.hook'
import { useUserCoordination } from './user-coordination.hook'
import { format } from '../../utils/format'
import { useCurrentBranch } from '../common/current-branch/current-branch.hook'
import { useRunner } from './runner.hook'
import { useFormErrors } from '../common/form/form-error.hook'
import { useActivityPlan } from './activity-plan.hook'
import { useSite } from './site.hook'
import { useActiveTab } from '../shared/active-tab/active-tab.hook'
import { fetchOutlayDashAsync } from '../../redux/budgeting/outlay-dash.slice'
import { useBranch } from '../common/branch.hook'
import { useProject } from './project.hook'
import { useComponent } from './component.hook'
import { useCostCategory } from './cost-category.hook'
import { useIntervention } from './intervention.hook'
import { useBudgetCode } from './budget-code.hook'
import { useRegion } from './region.hook'
import { useCopYear } from './cop-year.hook'
import activityService from '../../services/budgeting/activity.service'
import { useSurgeActivity } from './surge-activity.hook'

const useActivity = () => {
    const { branches } = useBranch()
    const { projects } = useProject()
    const { components } = useComponent()
    const { costCategories } = useCostCategory()
    const { interventions } = useIntervention()
    const { budgetCodes } = useBudgetCode()
    const { surgeActivityCodes } = useSurgeActivity()
    const { sites } = useSite()
    const { regions } = useRegion()
    const { copYears } = useCopYear()

    const { configuration } = useConfiguration()
    const { transactionCodes } = useTransactionCode()
    const { users } = useUser()
    const { currentUser } = useCurrentUser()
    const { userCoordinations } = useUserCoordination()
    const { currentBranch } = useCurrentBranch()
    const { runner, runners } = useRunner()
    const { setformError } = useFormErrors()
    const { activityPlans } = useActivityPlan()
    const { activeTab } = useActiveTab()

    const activities = useSelector<rootState, IActivity[]>(
        (state) => state.activity.activities
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.activity.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.activity.initialFetch
    )
    const activity = useSelector<rootState, IActivity>(
        (state) => state.activity.activity
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.activity.updateMode
    )
    const activitySummary = useSelector<rootState, IActivitySummary>(
        (state) => state.activity.activitySummary
    )
    const dispatch = useDispatch()

    const loadActivities = useCallback(
        (params: IFetchActivityParams) => {
            if (
                params.activityType === '' ||
                params.Branch === '' ||
                params.region === ''
            ) {
                return
            }
            if (initialFetch) {
                dispatch(activityActions.fetchActivitiesAsync(params))
            }
        },
        [dispatch, initialFetch]
    )

    const reloadActivities = useCallback(
        (params: IFetchActivityParams) => {
            if (
                params.activityType === '' ||
                params.Branch === '' ||
                params.region === ''
            ) {
                return
            }
            dispatch(activityActions.fetchActivitiesAsync(params))
            dispatch(fetchOutlayDashAsync(currentBranch.region))
        },
        [currentBranch.region, dispatch]
    )

    const getActivity = useCallback(
        (temporalJournal: IActivity): IActivity => {
            const user = users.find((x) => x.code === temporalJournal.userCode)
            const usrCode = user ? user.code : ''

            const activityPlan = activityPlans.find(
                (x) => x.code === temporalJournal.activity
            )
            const activity = activityPlan ? activityPlan.code : ''

            const site = sites.find((x) => x.code === temporalJournal.site)
            const siteCode = site ? site.code : ''

            return {
                ...temporalJournal,
                userCode: usrCode,
                activity,
                site: siteCode,
            }
        },
        [activityPlans, sites, users]
    )

    const getActivityBatch = useCallback(
        (batch: string) => {
            if (batch) {
                if (activities && activities.length) {
                    let journals: IActivity[] = activities.filter(
                        (x) => x.batch === batch
                    )
                    return journals.sort((a, b) =>
                        a.batchLine > b.batchLine ? 1 : -1
                    )
                }
                return []
            }
            return []
        },
        [activities]
    )

    const getActivities = useCallback(() => {
        return getCoreActivities()
            .filter((x) => x.project === configuration.project)
            .map((activity) => getActivity(activity))
    }, [activities, configuration.project, getActivity])

    const addActivity = async (activity: IActivity) => {
        debugger
        return await ActivityService.create(activity)
            .then((response) => {
                if (response.success) {
                    dispatch(activityActions.addActivitySuccess(response.data))
                }
                return response.success
            })
            .catch((error) => {
                setformError({
                    message: 'Failed',
                    success: false,
                    validationErrors: error,
                })
                return false
            })
    }

    const setActivity = (activity: IActivity) => {
        dispatch(activityActions.setActiveActivity(activity))
    }
    const setActivitySummary = useCallback(
        (activitySummary: IActivitySummary) => {
            dispatch(activityActions.setActiveActivitySummary(activitySummary))
        },
        [dispatch]
    )

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(activityActions.setActivityUpdateMode(updateMode))
    }

    const editActivity = async (Activity: IActivity) => {
        debugger
        return await ActivityService.update(Activity)
            .then((response) => {
                dispatch(activityActions.editActivitySuccess(response.data))
                setActivity(response.data)
                return true
            })
            .catch((error) => {
                setformError({
                    message: 'Failed',
                    success: false,
                    validationErrors: error,
                })
                return false
            })
    }

    const deleteActivity = async (activity: IActivity) => {
        return await activityService
            .delete(activity)
            .then((response) => {
                dispatch(activityActions.deleteActivitySuccess(response.data))
                setActivity(response.data)
                return true
            })
            .catch((error) => {
                console.log('del error: ', error)
                setformError({
                    message: 'Failed',
                    success: false,
                    validationErrors: error,
                })
                return false
            })
    }

    const saveActivity = async (Activity: IActivity, updateMode: UpdateMode) => {
        return updateMode === UpdateMode.ADD
            ? await addActivity(Activity)
            : await editActivity(Activity)
    }

    const getCoreActivities = () => {
        const currentPath = window.location.pathname.includes('surge-activities')
        const coreActivities = currentPath
            ? activities.filter((item) => surgeActivityCodes.includes(item.activity))
            : activities.filter(
                  (item) => !surgeActivityCodes.includes(item.activity)
              )
        return coreActivities
    }

    const getDistinctBatches = useCallback((): string[] => {
        const coreActivities = getCoreActivities()

        if (coreActivities) {
            if (configuration.project) {
                let distinctBatches = Array.from(
                    new Set(
                        coreActivities
                            .filter((x) => x.project === configuration.project)
                            .map((a) => a.batch)
                    )
                )
                return distinctBatches
            }
            return []
        }
        return []
    }, [activities, configuration.project])

    const getSummary = useCallback(
        (batch: string): IActivitySummary => {
            if (!activities) {
                return emptyActivitySummary
            }

            const coreActivities = getCoreActivities()

            const firstLine = coreActivities.find((x) => x.batch === batch)

            if (firstLine) {
                let filteredJournals = coreActivities.filter(
                    (x) => x.batch === batch
                )

                const user = users.find((x) => x.code === firstLine.userCode)
                const usrName = user ? user.usrName : ''

                const tranCode = transactionCodes.find(
                    (x) => x.code === firstLine.tranCode
                )
                const transactionCode = tranCode ? tranCode.code : ''
                const transactionCodeDescription = tranCode
                    ? tranCode.description
                    : ''

                const summary: IActivitySummary = {
                    batch,
                    number: filteredJournals.reduce(
                        (accumulator) => accumulator + 1,
                        0
                    ),
                    branch: firstLine.branch,
                    description: firstLine.description,
                    runner: firstLine.runner,
                    region: firstLine.region,
                    transDate: firstLine.transDate,
                    userCode: firstLine.userCode,
                    project: firstLine.project,
                    activityType: firstLine.activityType,
                    usrName,
                    transactionCode,
                    transactionCodeDescription,
                    amount: filteredJournals.reduce(
                        (accumulator, temporalJournal) =>
                            accumulator + temporalJournal.amount,
                        0
                    ),
                    quantity: filteredJournals.reduce(
                        (accumulator) => accumulator + 1,
                        0
                    ),
                }

                return summary
            }

            return emptyActivitySummary
        },
        [activities, users, transactionCodes]
    )

    const getActivitySummary = useCallback((): IActivitySummary[] => {
        let distinctBatches = getDistinctBatches()
        let journalSummary = distinctBatches.map((batch) => {
            return getSummary(batch)
        })
        return journalSummary.filter(
            (x) => x.transactionCode === configuration.tranCode
        )
    }, [getDistinctBatches, getSummary, configuration.tranCode])

    const getActivitySummaryUserFiltered = useCallback(() => {
        return getActivitySummary().filter((x) => x.userCode === currentUser.code)
    }, [currentUser.code, getActivitySummary])

    const getActivitySummaryCoordinationAreaFiltered = useCallback(() => {
        const coordinationAreaUsers = [
            ...userCoordinations
                .filter((x) => x.user === currentUser.code)
                .map((x) => x.coordination),
            currentUser.code,
        ]

        return getActivitySummary().filter((x) =>
            coordinationAreaUsers.includes(x.userCode)
        )
    }, [getActivitySummary, currentUser.code, userCoordinations])

    const getActivityTabData = useCallback(() => {
        switch (activeTab.isActive) {
            case 'site-activities':
                return getActivitySummaryUserFiltered()
            case 'coordination-area-activities':
                return getActivitySummaryCoordinationAreaFiltered()
            case 'regional-activities':
                return getActivitySummary()
            default:
                return []
        }
    }, [
        activeTab.isActive,
        getActivitySummary,
        getActivitySummaryCoordinationAreaFiltered,
        getActivitySummaryUserFiltered,
    ])

    const getTotalAmount = useCallback(() => {
        return format.number(
            getActivityTabData()
                .map((journal) => journal.amount)
                .reduce((a, b) => a + b, 0)
        )
    }, [getActivityTabData])

    const getInitialActivity = useCallback(() => {
        return {
            ...emptyActivity,
            tenant: currentBranch.tenant,
            region: currentBranch.region,
            batch: '0000000',
            batchLine: '00000',
            sense: 'D',
            runner: runner.code,
            activityType: configuration.activityType,
            tranCode: configuration.tranCode,
            project: configuration.project,
            branch: currentBranch.code,
            copYear: runner.copYear,
            component: runner.component,
            transDate: runner.startDate!,
            userCode: currentUser.code,
        }
    }, [
        configuration.activityType,
        configuration.project,
        configuration.tranCode,
        currentBranch.code,
        currentBranch.region,
        currentBranch.tenant,
        currentUser.code,
        runner.code,
        runner.component,
        runner.copYear,
        runner.startDate,
    ])

    const getMapedExcelActivity = (activity: IActivity) => {
        return {
            ...activity,
            userCode: users.find((user) => user.code === activity.userCode)?.usrName,
            branch: branches.find((branch) => activity.branch === branch.code)
                ?.branchName,
            project: projects.find((project) => activity.project === project.code)
                ?.description,
            component: components.find(
                (component) => activity.component === component.code
            )?.description,
            costCategory: costCategories.find(
                (cc) => activity.costCategory === cc.code
            )?.description,
            intervention: interventions.find(
                (intervention) => activity.intervention === intervention.code
            )?.code,
            budgetCode: budgetCodes.find((bc) => activity.budgetCode === bc.code)
                ?.description,
            site: sites.find((site) => activity.site === site.code)?.description,
            region: regions.find((region) => activity.region === region.code)
                ?.description,
            copYear: copYears.find((copYear) => activity.copYear === copYear.code)
                ?.description,
            runner: runners.find((runner) => runner.code === activity.runner)
                ?.description,
        }
    }

    const getMapedExcelActivities = (activities: IActivity[]) =>
        activities.map((item) => getMapedExcelActivity(item))

    useEffect(() => {
        var params: IFetchActivityParams = {
            activityType: '',
            Branch: '',
            region: '',
        }
        loadActivities(params)
    }, [
        configuration,
        activity,
        activities,
        isLoading,
        initialFetch,
        loadActivities,
        getActivityTabData,
        getActivitySummary,
        getActivitySummaryUserFiltered,
        getActivitySummaryCoordinationAreaFiltered,
        userCoordinations,
    ])

    return {
        activity: getActivity(activity),
        activities: getActivities(),
        activitiesSummary: getActivitySummary(),
        activitiesUserSummary: getActivitySummaryUserFiltered(),
        activitiesCoordinationAreaSummary:
            getActivitySummaryCoordinationAreaFiltered(),
        activitySummaryTabbed: getActivityTabData(),
        totalAmount: getTotalAmount(),
        isLoading,
        initialFetch,
        updateMode,
        getActivityBatch,
        setUpdateMode,
        addActivity,
        editActivity,
        deleteActivity,
        saveActivity,
        setActivity,
        loadActivities,
        reloadActivities,
        activitySummary,
        setActivitySummary,
        initialActivity: getInitialActivity(),
        getMapedExcelActivities,
    }
}

export { useActivity }
