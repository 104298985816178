import { Button, Card, Col, List, Row, Typography } from 'antd'
import React, { useCallback, useContext } from 'react'
import { FiEdit } from 'react-icons/fi'
import styled from 'styled-components'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useActivity } from '../../../hooks/budgeting/activity.hook'
import { useCostCategory } from '../../../hooks/budgeting/cost-category.hook'
import { useIntervention } from '../../../hooks/budgeting/intervention.hook'
import { useSite } from '../../../hooks/budgeting/site.hook'
import { useStrategy } from '../../../hooks/budgeting/strategy.hook'
import { useConfiguration } from '../../../hooks/configuration.hook'
import { IActivity } from '../../../models/budgeting/activity.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import { format } from '../../../utils/format'
import ActivityForm from '../activity/activity-form.component'
import './activity-summary.style.scss'

type Props = {
    activity: IActivity
    showEdit?: boolean
}

const ActivitySummaryDetails: React.FC<Props> = ({ activity, showEdit = false }) => {
    const { costCategories } = useCostCategory()
    const { getInterventionBudgetCode, getInterventionDescription } =
        useIntervention()
    const { sites } = useSite()
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)
    const { setActivity } = useActivity()
    const { getStragtegyDescription } = useStrategy()
    const { configuration } = useConfiguration()
    // console.log("configuration: ", configuration)

    const getSite = useCallback(
        (siteCode: string) => {
            return sites && sites.length > 0
                ? sites.find((site) => site.code === siteCode)?.description
                : 'Not Found'
        },
        [sites]
    )

    const handleEdit = () => {
        setActivity(activity)
        setWidth('40rem')
        setShow(true)
        setContent(<ActivityForm updateMode={UpdateMode.EDIT} />)
        setTitle('Edit Activity')
    }
    return (
        <Card size="small">
            <List
                size="small"
                dataSource={[
                    {
                        label: 'Description',
                        value: activity.description,
                    },
                    {
                        label: 'Amount',
                        value: format.number(activity.amount),
                    },
                    {
                        label: 'Activity',
                        value: activity.activity,
                    },
                    {
                        label: 'Site',
                        value: getSite(activity.site),
                    },
                    configuration.project === '02'
                        ? {
                              label: 'Strategy',
                              value: getStragtegyDescription(activity.strategy!),
                          }
                        : {
                              label: 'Budget Code',
                              value: getInterventionBudgetCode(activity.budgetCode!),
                          },
                    {
                        label: 'Cost Category',
                        value:
                            costCategories && costCategories.length > 0
                                ? costCategories.find(
                                      (item) => item.code === activity.costCategory
                                  )?.description
                                : 'None',
                    },
                    {
                        label: 'Intervention',
                        value: getInterventionDescription(activity.intervention!),
                    },
                    {
                        label: 'Batch',
                        value: `${activity.batch} - ${activity.batchLine}`,
                    },
                ]}
                renderItem={(item) => (
                    <List.Item>
                        <Row style={{ width: '100%' }}>
                            <Col md={4}>
                                <Typography.Text>{item.label}</Typography.Text>
                            </Col>
                            <Col md={20}>
                                <Typography.Text>{item.value}</Typography.Text>
                            </Col>
                        </Row>
                    </List.Item>
                )}
            />
            {showEdit && (
                <Row>
                    <Col span={4} xs={{ span: 24 }} style={{ marginTop: 15 }}>
                        <StyledParagraph>
                            <Button
                                type="primary"
                                htmlType="button"
                                onClick={handleEdit}
                            >
                                <div
                                    style={{ display: 'flex', alignItems: 'center' }}
                                >
                                    <FiEdit />{' '}
                                    <span style={{ marginLeft: 5 }}>
                                        Edit Activity
                                    </span>
                                </div>
                            </Button>
                        </StyledParagraph>
                    </Col>
                    <Col span={19} xs={{ span: 24 }}></Col>
                </Row>
            )}
        </Card>
    )
}

export default ActivitySummaryDetails

const StyledParagraph = styled.p`
    margin-bottom: 0;
    margin-left: 35px;

    @media (max-width: 576px) {
        margin-left: 0;
    }
`
